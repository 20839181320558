<template>
  <div>
    <el-row style="margin-top: 20px;">
      <el-col :span="14" :offset="4">

        <el-form :model="applyForm" :rules="rules" ref="applyForm" label-width="100px" class="applyForm" >
          <header class="form_header">请按照要求填写培训信息</header>
          <br/>
          <el-form-item label="培训主题" prop="title">
            <el-input v-model="applyForm.title"  maxlength="20"  show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="培训介绍">
            <el-input type="textarea" v-model="applyForm.content"  maxlength="100"  show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="主讲嘉宾">
            <el-input v-model="applyForm.host" type="textarea" maxlength="50"  show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="培训群体" prop="consumer" >
            <el-select v-model="applyForm.consumer" placeholder="请选择培训群体" >
              <el-option
                v-for="item in consumer_options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
<!--              <el-option label="所有律所均可参加" :value=false></el-option>-->
<!--              <el-option label="只允许本律所律师参加" :value=true></el-option>-->
            </el-select>
          </el-form-item>

          <el-form-item label="最多可报名人数" prop="enrollment">
            <el-input-number v-model="applyForm.enrollment" :min="1" :max="1000" label="描述文字"></el-input-number>
          </el-form-item>

          <el-form-item label="培训负责人" >
            <el-input v-model="applyForm.contacter" maxlength="10"  show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="联系方式">
            <el-input v-model.number="applyForm.phone" maxlength="11"  show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="培训位置" prop="mapAddress">
            <el-select
              style="width:70%"
              v-model="tipInput"
              class="tipInput"
              filterable
              remote
              reserve-keyword
              clearable
              placeholder="请输入关键词并根据提示选择地点"
              :remote-method="remoteMethod"
              :loading="loading"
              @change="tipChange">
              <el-option
                v-for="item in tips"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.district }}</span>
              </el-option>
            </el-select>
            <br>
            <br>
            <!--    高德地图自带的，是获取id的值不是获取class -->
            <div id="a-map" style="height: 400px;width: 100%">
            </div>
          </el-form-item>

          <el-form-item label="详细地点" prop="address">
            <el-input type="textarea" v-model="applyForm.address" maxlength="50"  show-word-limit></el-input>
          </el-form-item>

          <el-form-item label="培训时间" prop="trainTime">
            <div class="block">
              <span class="demonstration"></span>
              <el-date-picker
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="applyForm.trainTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间">
              </el-date-picker>
            </div>
          </el-form-item>


          <el-form-item label="培训要求">
            <el-input type="textarea" v-model="applyForm.memo" maxlength="50"  show-word-limit></el-input>
          </el-form-item>

          <br>

          <el-form-item>
            <div style="text-align:center">
              <el-button size="medium" type="primary" style="width: 300px" @click=" beforeSubmit('applyForm')">申报培训</el-button>
            </div>
          </el-form-item>
        </el-form>

      </el-col>
    </el-row>

<!--    <div v-model="applyForm">{{applyForm}}</div>-->
  </div>
</template>
<script>
import { train_consumer_options} from '@/utils/dict'
import { request } from '@/network/network'
export default {

  data() {
    return {
      //地图
      selectAddress:'',
      tipInput : '',
      loading: false,
      map: undefined,
      tips: [],

      consumer_options: train_consumer_options,

      adminInformation: JSON.parse(sessionStorage.getItem("information")),

      applyForm: {
        title: '',
        content: '',
        host: '',
        contacter:'',
        phone: '',
        consumer: '',
        enrollment: 50,
        score:'',
        status:0,
        info:'',
        mapAddress:'',
        address: '',
        //用来接收组件的时间，提交前赋值给startTime和endTime
        trainTime:[],
        startTime: '',
        endTime: '',
        memo: '',
        out_code:'',
        sign_code:'',
        did:"",
      },
      rules: {
        title: [
          { required: true, message: '请输入培训主题', trigger: 'blur' },
          { min: 1, max: 20, message: '不能多于50字', trigger: 'blur' }
        ],
        consumer: [
          {required: true, message: '请选择培训群体', trigger: 'blur' },
        ],
        enrollment: [
          { required: true, message: '请选择最多可报名人数', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '培训地址不能为空', trigger: 'blur' },

        ],
        trainTime: [
          { required: true, message: '请选择培训时间', trigger: 'blur' }
        ],
      },
    };
  },
  methods: {
    beforeSubmit(applyForm) {
      console.log(this.applyForm)
      this.$refs[applyForm].validate((valid) => {
        if (valid) {
          this.submitForm()
        } else {
          this.$message({
            message: '请按要求完善培训信息！',
            type: 'warning',
            duration: 1000,
          });
          return false;
        }
      });
    },
    submitForm() {

      // this.applyForm.startTime = this.applyForm.trainTime[0]
      // this.applyForm.endTime = this.applyForm.trainTime[1]
      this.$set(this.applyForm, 'startTime', this.applyForm.trainTime[0])
      this.$set(this.applyForm, 'endTime', this.applyForm.trainTime[1])
      this.applyForm.mapAddress = JSON.stringify(this.selectAddress)
      this.applyForm.did = this.adminInformation.did
      console.log(this.applyForm)

      if(this.applyForm.mapAddress == ''){
        this.$message({
          message: "请选择培训位置！",
          type: "error",
          duration: 1000,
        });}
      else{
        const loading = this.$loading({
          lock: true,
          text: '请稍等',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        this.addTrain(this.applyForm)
          .then((res) => {
            loading.close();
            this.$message({
              message: "申请成功！",
              type: "success",
              duration: 1000,
            });
            this.$router.push("myApplyTrainF")
          })
          .catch((err) => {
            loading.close();
            this.$message({
              message: "发生错误，新增失败！",
              type: "error",
              duration: 1000,
            });
            this.$router.go(0)
          });

      }

    },
    addTrain(train){
      return request({
        url: '/train/applyTrain',
        method: 'post',
        data: train
      })
    },

    //地图
    initMap() {
      this.map = new AMap.Map("a-map", {
        resizeEnable: true,
        zoom: 13,//地图显示的缩放级别
        keyboardEnable: false
      });
    },
    tipChange(value) {
      for (const tip of this.tips) {

        if (value === tip.id) {
          this.selectAddress = tip
          this.map.setZoom(17)
          this.map.setCenter(tip.location)
          break
        }
      }
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        const that = this
        AMap.plugin('AMap.Autocomplete', function(){
          // 实例化Autocomplete
          const autoOptions = {
            city: '海口'
          }
          const autoComplete = new AMap.Autocomplete(autoOptions);
          autoComplete.search(query, function(status, result) {
            // 搜索成功时，result即是对应的匹配数据
            that.tips = result.tips || []

            that.loading = false;
          })
        })
      } else {
        this.tips = [];
      }
    }

  },
  mounted () {
    this.initMap()
  }

}
</script>

<style lang="scss" scoped>

.autoAddressClass {
  li {
    i.el-icon-search {
      margin-top: 11px;
    }

    .mgr10 {
      margin-right: 10px;
    }

    .title {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .address {
      line-height: 1;
      font-size: 12px;
      color: #b4b4b4;
      margin-bottom: 5px;
    }
  }
}

</style>
